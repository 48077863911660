import { wrapUseRoutes, ErrorBoundary } from '@sentry/react';
import { PropTypes } from 'prop-types';
import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation, Outlet } from 'react-router-dom';
import { LoadingScreen } from '@boletia/blt-ui';
import RequireAuth from './require-auth';
import RequireRol from './require-rol';
import RequireSuperAdmin from './require-superadmin';
import AdminLayout from '../layouts/admin';
import EventLayout from '../layouts/event';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import MyAccountPage from '../modules/events/pages/MyAccount';
import Surveys from '../modules/surveys/pages/Surveys';
import { PATH_PP, PATH_TC } from './paths';
import CheckinLayout from '../layouts/check-in';
import RequireParentEvent from './require-parent-event';
import useTester from '../hooks/useTester';
import RequireTester from './require-tester';
import RequireV2 from './require-v2';
import ErrorFallback from '../pages/ErrorFallback';

const useSentryRoutes = wrapUseRoutes(useRoutes);

const LoadableComponent = ({ Component, ...props }) => {
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <ErrorBoundary
        fallback={({ error, resetError }) => (
          <ErrorFallback error={error} resetError={resetError} />
        )}
      >
        <Component {...props} />
      </ErrorBoundary>
    </Suspense>
  );
};

LoadableComponent.propTypes = {
  Component: PropTypes.elementType.isRequired,
};

const Loadable = (Component) => (props) =>
  <LoadableComponent Component={Component} {...props} />;

export default function Router() {
  const { isTester: showOldStatistics } = useTester({
    testerType: 'showOldStatistics',
  });

  return useSentryRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: <Login />,
        },
        {
          path: 'register',
          element: <Register />,
        },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'reset-weak-password', element: <ResetWeakPassword /> },
        { path: 'new-password', element: <NewPassword /> },
        {
          path: 'change-password',
          element: (
            <RequireAuth>
              <ChangePassword />
            </RequireAuth>
          ),
        },
        { path: 'verify', element: <VerifyEmailPage /> },
      ],
    },
    {
      path: '/',
      element: <Navigate to="/dashboard/events" replace />,
    },
    {
      path: '/register-incode',
      element: (
        <RequireAuth validateChangePass>
          <RegisterWithIncode />
        </RequireAuth>
      ),
    },
    {
      path: '/dashboard',
      element: (
        <ErrorBoundary
          fallback={({ error, resetError }) => (
            <ErrorFallback error={error} resetError={resetError} />
          )}
        >
          <RequireAuth validateChangePass>
            <AdminLayout />
          </RequireAuth>
        </ErrorBoundary>
      ),
      children: [
        { element: <Navigate to="/dashboard/events" replace />, index: true },
        { path: 'events', element: <EventsPage /> },
        { path: 'event-create', element: <EventCreate /> },
        { path: 'my-account', element: <MyAccountPage /> },
        {
          path: 'billboards',
          element: <Billboards />,
        },
        {
          path: 'billboards-create/:billboard_type',
          element: <BillboardCreate />,
        },
        {
          path: 'billboards-edit/:billboard',
          element: (
            <RequireRol rol="owner">
              <Billboard />
            </RequireRol>
          ),
        },
        {
          path: 'campaigns',
          element: (
            <RequireTester testerType="showCampaigns">
              <Outlet />
            </RequireTester>
          ),
          children: [
            { path: '', element: <Campaigns /> },
            { path: 'create', element: <CreateCampaign /> },
            { path: ':campaign/create-ad', element: <CreateAd /> },
            { path: 'add-balance', element: <AddBalance /> },
            { path: ':campaign/edit', element: <EditCampaign /> },
            { path: ':campaign/edit-ad/:ad', element: <EditAd /> },
            { path: '*', element: <Navigate to="" replace /> },
          ],
        },
        { path: 'team', element: <MyTeam /> },
        { path: 'team-edit/:user', element: <MyTeaEdit /> },
        { path: 'team-add', element: <MyTeamAdd /> },
        { path: 'statistics', element: <GlobalStatistics /> },
        {
          path: 'unarchive',
          element: (
            <RequireSuperAdmin>
              <Unarchive />
            </RequireSuperAdmin>
          ),
        },
        {
          path: 'search',
          element: (
            <RequireSuperAdmin>
              <SuperAdminSearch />
            </RequireSuperAdmin>
          ),
        },
        {
          path: 'delete-accounts',
          element: (
            <RequireSuperAdmin>
              <DeleteAccounts />
            </RequireSuperAdmin>
          ),
        },
        {
          path: 'change-ownership',
          element: (
            <RequireSuperAdmin>
              <ChangeOwnership />
            </RequireSuperAdmin>
          ),
        },
        {
          path: 'set-password',
          element: (
            <RequireSuperAdmin>
              <SetPassword />
            </RequireSuperAdmin>
          ),
        },
        {
          path: 'bines',
          element: (
            <RequireSuperAdmin>
              <RequireTester testerType="showPaymentMethods">
                <Bines />
              </RequireTester>
            </RequireSuperAdmin>
          ),
        },
        {
          path: 'bines/:bank',
          element: (
            <RequireSuperAdmin>
              <Bank />
            </RequireSuperAdmin>
          ),
        },
        {
          path: 'wallet',
          element: (
            <RequireTester testerType="showWallet">
              <TicketAccess />
            </RequireTester>
          ),
        },
        {
          path: 'active-campaigns',
          element: (
            <RequireTester testerType="showActiveCampaigns">
              <Outlet />
            </RequireTester>
          ),
          children: [
            { path: '', element: <ActiveCampaigns /> },
            {
              path: 'campaign-review/:campaign/:user',
              element: <CampaignReview />,
            },
          ],
        },
        {
          path: 'check-in',
          children: [
            {
              element: (
                <Navigate to="/dashboard/check-in/events-control" replace />
              ),
              index: true,
            },
            {
              path: 'events-control',
              element: <CheckinEvents />,
            },
            {
              path: '*',
              element: (
                <Navigate to="/dashboard/check-in/events-control" replace />
              ),
            },
          ],
        },
      ],
    },
    {
      path: '/event/:event',
      element: (
        <ErrorBoundary
          fallback={({ error, resetError }) => (
            <ErrorFallback error={error} resetError={resetError} />
          )}
        >
          <RequireAuth validateChangePass>
            <RequireRol rol="analyst">
              <EventLayout />
            </RequireRol>
          </RequireAuth>
        </ErrorBoundary>
      ),
      children: [
        {
          path: 'dashboard',
          element: (
            <RequireRol rol="analyst" exclude="editor">
              <DashboardLayout />
            </RequireRol>
          ),
          children: [
            {
              element: <Navigate to="sales" replace />,
              index: true,
            },
            {
              path: 'sales',
              element: (
                <RequireRol rol="analyst" exclude="editor">
                  <Sales />
                </RequireRol>
              ),
            },
            {
              path: 'clients',
              element: (
                <RequireRol rol="analyst" exclude="editor">
                  <Clients />
                </RequireRol>
              ),
            },
            {
              path: 'funnel',
              element: (
                <RequireRol rol="analyst" exclude="editor">
                  <Funnel />
                </RequireRol>
              ),
            },
          ],
        },
        {
          path: 'dashboard/general',
          element: (
            <RequireRol rol="analyst" exclude="editor">
              <Dashboard />
            </RequireRol>
          ),
        },
        {
          path: 'stats',
          element: (
            <RequireRol rol="analyst" exclude="editor">
              <Statistics2 />
            </RequireRol>
          ),
        },
        {
          path: 'statistic-old',
          element: <>{showOldStatistics && <Statistics />}</>,
        },
        {
          path: 'conf/edit',
          element: (
            <RequireRol rol="analyst">
              <EventEdit />
            </RequireRol>
          ),
        },
        {
          path: 'conf/map',
          element: (
            <RequireRol rol="editor" exclude="analyst">
              <TicketsMap />
            </RequireRol>
          ),
        },
        {
          path: 'conf/map/selection',
          element: (
            <RequireRol rol="editor" exclude="analyst">
              <TicketsMapSelection />
            </RequireRol>
          ),
        },
        {
          path: 'conf/tickets',
          element: (
            <RequireRol rol="editor" exclude="analyst">
              <Tickets />
            </RequireRol>
          ),
        },
        {
          path: 'conf/tickets/create',
          element: (
            <RequireRol rol="editor" exclude="analyst">
              <TicketCreate />
            </RequireRol>
          ),
        },
        {
          path: 'conf/tickets/edit/:ticket',
          element: (
            <RequireRol rol="editor" exclude="analyst">
              <TicketEdit />
            </RequireRol>
          ),
        },
        {
          path: 'conf/holds',
          element: (
            <RequireV2>
              <RequireRol rol="partner" exclude="analyst">
                <Holds />
              </RequireRol>
            </RequireV2>
          ),
        },
        {
          path: 'conf/holds/create',
          element: (
            <RequireV2>
              <RequireRol rol="editor" exclude="analyst">
                <HoldsCreate />
              </RequireRol>
            </RequireV2>
          ),
        },
        {
          path: 'conf/holds/view/:hold',
          element: (
            <RequireV2>
              <RequireRol rol="editor" exclude="analyst">
                <HoldsView />
              </RequireRol>
            </RequireV2>
          ),
        },
        {
          path: 'conf/holds/edit/:hold',
          element: (
            <RequireV2>
              <RequireRol rol="editor" exclude="analyst">
                <HoldsEdit />
              </RequireRol>
            </RequireV2>
          ),
        },
        {
          path: 'conf/holds/:hold/create-batch',
          element: (
            <RequireV2>
              <RequireRol rol="editor" exclude="analyst">
                <BatchCreate />
              </RequireRol>
            </RequireV2>
          ),
        },
        {
          path: 'conf/holds/:hold/batch/:batch',
          element: (
            <RequireV2>
              <RequireRol rol="editor" exclude="analyst">
                <Batch />
              </RequireRol>
            </RequireV2>
          ),
        },
        {
          path: 'conf/payments',
          element: (
            <RequireRol rol="editor" exclude="analyst">
              <Payments />
            </RequireRol>
          ),
        },
        {
          path: 'conf/surveys',
          element: (
            <RequireRol rol="editor" exclude="analyst">
              <Surveys />
            </RequireRol>
          ),
        },
        {
          path: 'rsvp/dashboard',
          element: (
            <RequireRol rol="editor" exclude="analyst">
              <RsvpDasboard />
            </RequireRol>
          ),
        },
        {
          path: 'rsvp/links/:link',
          element: (
            <RequireRol rol="editor" exclude="analyst">
              <RsvpLinks />
            </RequireRol>
          ),
        },
        {
          path: 'services/invitations',
          element: (
            <RequireRol rol="editor" exclude="analyst">
              <Invitations />
            </RequireRol>
          ),
        },
        {
          path: 'services/bines',
          element: (
            <RequireRol rol="editor" exclude="analyst">
              <Maintenance />
            </RequireRol>
          ),
        },
        {
          path: 'services/promotions',
          element: (
            <RequireRol rol="editor" exclude="analyst">
              <Promotions />
            </RequireRol>
          ),
        },
        {
          path: 'services/services',
          element: (
            <RequireRol rol="editor" exclude="analyst">
              <Services />
            </RequireRol>
          ),
        },
        {
          path: 'services/checkin',
          element: (
            <RequireRol rol="editor" exclude="analyst">
              <Checkin />
            </RequireRol>
          ),
        },
        {
          path: 'services/rspv',
          element: (
            <RequireRol rol="editor" exclude="analyst">
              <Rspv />
            </RequireRol>
          ),
        },
        {
          path: 'services/duplicate',
          element: (
            <RequireRol rol="owner">
              <Duplicate />
            </RequireRol>
          ),
        },
        {
          path: 'reservations/totals',
          element: (
            <RequireRol rol="analyst" exclude="editor">
              <TotalReservation />
            </RequireRol>
          ),
        },
        {
          path: 'reservations/totals/:booking',
          element: (
            <RequireRol rol="analyst" exclude="editor">
              <Booking />
            </RequireRol>
          ),
        },
        { path: 'reservations/seller', element: <ComingSoon /> },
        { path: 'reservations/insider', element: <ComingSoon /> },
        { path: 'reservations/tickets', element: <ComingSoon /> },
        { path: 'reservations/history', element: <ComingSoon /> },
        { path: 'sales', element: <ComingSoon /> },
        {
          path: 'history',
          element: (
            <RequireRol rol="partner" exclude="editor">
              <History />
            </RequireRol>
          ),
        },
      ],
    },
    {
      path: 'check-in/:event',
      element: (
        <ErrorBoundary
          fallback={({ error, resetError }) => (
            <ErrorFallback error={error} resetError={resetError} />
          )}
        >
          <RequireAuth validateChangePass>
            <CheckinLayout />
          </RequireAuth>
        </ErrorBoundary>
      ),
      children: [
        {
          path: 'attendees',
          element: (
            <RequireParentEvent>
              <CheckinAttendees />
            </RequireParentEvent>
          ),
        },
        {
          path: 'records',
          element: (
            <RequireParentEvent>
              <CheckinRecords />
            </RequireParentEvent>
          ),
        },
        {
          path: 'checkpoints',
          element: (
            <RequireParentEvent>
              <Checkpoints />
            </RequireParentEvent>
          ),
        },
      ],
    },
    {
      path: PATH_TC,
      element: <TermsAndConditionsPage />,
    },
    {
      path: PATH_PP,
      element: <PrivacyPolicy />,
    },
    {
      path: '*',
      element: (
        <ErrorBoundary
          fallback={({ error, resetError }) => (
            <ErrorFallback error={error} resetError={resetError} />
          )}
        >
          <LogoOnlyLayout />
        </ErrorBoundary>
      ),
      children: [
        { path: '403', element: <Forbidden /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const RegisterWithIncode = Loadable(
  lazy(() => import('../pages/auth/RegisterWithIncode')),
);
const ResetPassword = Loadable(
  lazy(() => import('../pages/auth/ResetPassword')),
);
const ResetWeakPassword = Loadable(
  lazy(() => import('../pages/auth/ResetWeakPassword')),
);
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const ChangePassword = Loadable(
  lazy(() => import('../pages/auth/ChangePassword')),
);
const VerifyEmailPage = Loadable(
  lazy(() => import('../pages/auth/VerifyEmailPage')),
);

// Test
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const Forbidden = Loadable(lazy(() => import('../pages/Page403')));

// Dashboard
const EventsPage = Loadable(
  lazy(() => import('../modules/events/pages/Events')),
);
const EventCreate = Loadable(
  lazy(() => import('../modules/events/pages/EventCreate')),
);
const Billboards = Loadable(
  lazy(() => import('../modules/billboards/pages/Billboards')),
);
const BillboardCreate = Loadable(
  lazy(() => import('../modules/billboards/pages/BillboardCreate')),
);
const Billboard = Loadable(
  lazy(() => import('../modules/billboards/pages/Billboard')),
);

// Campaigns
const Campaigns = Loadable(
  lazy(() => import('../modules/adtech/pages/Campaigns')),
);
const CreateCampaign = Loadable(
  lazy(() => import('../modules/adtech/pages/CreateCampaign')),
);
const CreateAd = Loadable(
  lazy(() => import('../modules/adtech/pages/CreateAd')),
);
const AddBalance = Loadable(
  lazy(() => import('../modules/adtech/pages/AddBalance')),
);
const EditCampaign = Loadable(
  lazy(() => import('../modules/adtech/pages/EditCampaign')),
);
const EditAd = Loadable(lazy(() => import('../modules/adtech/pages/EditAd')));

// Events
const Dashboard = Loadable(
  lazy(() => import('../modules/dashboard/pages/Dashboard')),
);

const DashboardLayout = Loadable(
  lazy(() => import('../modules/dashboard/pages/DashboardLayout')),
);

const Sales = Loadable(lazy(() => import('../modules/dashboard/pages/Sales')));

const Clients = Loadable(
  lazy(() => import('../modules/dashboard/pages/Clients')),
);

const Funnel = Loadable(
  lazy(() => import('../modules/dashboard/pages/Funnel')),
);

const EventEdit = Loadable(
  lazy(() => import('../modules/events/pages/EventEdit')),
);
const Payments = Loadable(
  lazy(() => import('../modules/events/pages/Payments')),
);
const Duplicate = Loadable(
  lazy(() => import('../modules/duplicate/pages/Duplicate')),
);
const Promotions = Loadable(
  lazy(() => import('../modules/promotions/pages/Promotions')),
);
const Tickets = Loadable(
  lazy(() => import('../modules/tickets/pages/Tickets')),
);
const TicketCreate = Loadable(
  lazy(() => import('../modules/tickets/pages/TicketCreate')),
);
const TicketEdit = Loadable(
  lazy(() => import('../modules/tickets/pages/TicketEdit')),
);
const TicketsMapSelection = Loadable(
  lazy(() => import('../modules/tickets/pages/TicketsMapSelection')),
);
const TicketsMap = Loadable(
  lazy(() => import('../modules/tickets/pages/TicketsMap')),
);
const Holds = Loadable(lazy(() => import('../modules/holds/pages/Holds')));
const HoldsCreate = Loadable(
  lazy(() => import('../modules/holds/pages/HoldsCreate')),
);
const HoldsView = Loadable(
  lazy(() => import('../modules/holds/pages/HoldsView')),
);
const HoldsEdit = Loadable(
  lazy(() => import('../modules/holds/pages/HoldsEdit')),
);
const BatchCreate = Loadable(
  lazy(() => import('../modules/holds/pages/BatchCreate')),
);
const Batch = Loadable(lazy(() => import('../modules/holds/pages/Batch')));

const RsvpDasboard = Loadable(
  lazy(() => import('../modules/rspv/pages/Dashboard')),
);

const RsvpLinks = Loadable(lazy(() => import('../modules/rspv/pages/Links')));

const Rspv = Loadable(lazy(() => import('../modules/rspv/pages/Rspv')));
const Checkin = Loadable(
  lazy(() => import('../modules/checkin/pages/Checkin')),
);
const Services = Loadable(
  lazy(() => import('../modules/services/pages/Services')),
);
const History = Loadable(lazy(() => import('../modules/events/pages/History')));

// Reservations
const TotalReservation = Loadable(
  lazy(() => import('../modules/reservations/pages/TotalReservation')),
);
const Booking = Loadable(
  lazy(() => import('../modules/reservations/pages/Booking')),
);
const Invitations = Loadable(
  lazy(() => import('../modules/invitations/pages/Invitations')),
);

// Terms and Conditions
const TermsAndConditionsPage = Loadable(
  lazy(() => import('../pages/TermsAndConditionsPage')),
);

// Privacy Policy
const PrivacyPolicy = Loadable(
  lazy(() => import('../pages/PrivacyPolicyPage')),
);

// Team
const MyTeam = Loadable(lazy(() => import('../modules/team/pages/MyTeam')));
const MyTeaEdit = Loadable(
  lazy(() => import('../modules/team/pages/MyTeamEdit')),
);
const MyTeamAdd = Loadable(
  lazy(() => import('../modules/team/pages/MyTeamAdd')),
);

// Statistics
const Statistics = Loadable(
  lazy(() => import('../modules/statistics/pages/Statistics')),
);
const Statistics2 = Loadable(
  lazy(() => import('../modules/statistics-v2/pages/Statistics2')),
);
const GlobalStatistics = Loadable(
  lazy(() => import('../modules/statistics/pages/GlobalStatistics')),
);

// Super Admin
const Unarchive = Loadable(
  lazy(() => import('../modules/superadmin/pages/Unarchive')),
);
const SuperAdminSearch = Loadable(
  lazy(() => import('../modules/superadmin/pages/Search')),
);
const DeleteAccounts = Loadable(
  lazy(() => import('../modules/superadmin/pages/DeleteAccounts')),
);
const SetPassword = Loadable(
  lazy(() => import('../modules/superadmin/pages/SetPassword')),
);

const ChangeOwnership = Loadable(
  lazy(() => import('../modules/superadmin/pages/ChangeOwnership')),
);

const Bines = Loadable(lazy(() => import('../modules/superadmin/pages/Bines')));
const Bank = Loadable(lazy(() => import('../modules/superadmin/pages/Bank')));
const TicketAccess = Loadable(
  lazy(() => import('../modules/superadmin/pages/Wallet')),
);
const ActiveCampaigns = Loadable(
  lazy(() => import('../modules/superadmin/pages/ActiveCampaigns')),
);
const CampaignReview = Loadable(
  lazy(() => import('../modules/superadmin/pages/CampaignReview')),
);

// Check In
const Checkpoints = Loadable(
  lazy(() => import('../modules/checkpoints/pages/Checkpoints')),
);

const CheckinEvents = Loadable(
  lazy(() => import('../modules/checkin/pages/CheckinEvents')),
);
const CheckinAttendees = Loadable(
  lazy(() => import('../modules/checkin/pages/CheckinAttendees')),
);
const CheckinRecords = Loadable(
  lazy(() => import('../modules/checkin/pages/CheckinRecords')),
);
